var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.displayLoading)?_c('loading'):_vm._e(),_c('header',{staticClass:"d-flex justify-content-between align-items-center"},[_c('span',{staticClass:"text-left"},[(false)?_c('button',{staticClass:"btn text-white"},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 256 512"}},[_c('path',{attrs:{"d":"M192 448c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l137.4 137.4c12.5 12.5 12.5 32.75 0 45.25C208.4 444.9 200.2 448 192 448z"}})]),_vm._v(" 回選單 ")]):_vm._e()]),_c('h1',[_vm._v("客戶清單")]),_c('span',{staticClass:"text-right"})]),(false)?_c('ul',{staticClass:"customer-tab nav nav-tabs d-flex justify-content-between"},[_c('li',{staticClass:"nav-item flex-fill"},[_c('a',{class:("nav-link " + (_vm.currentTab == 'new' && 'active')),on:{"click":function () {
            _vm.currentTab = 'new';
          }}},[_vm._v("新顧客")])]),_c('li',{staticClass:"nav-item flex-fill"},[_c('a',{class:("nav-link " + (_vm.currentTab == 'old' && 'active')),on:{"click":function () {
            _vm.currentTab = 'old';
          }}},[_vm._v("老顧客")])]),_c('li',{staticClass:"nav-item flex-fill"},[_c('a',{class:("nav-link " + (_vm.currentTab == 'lost' && 'active')),on:{"click":function () {
            _vm.currentTab = 'lost';
          }}},[_vm._v("失顧聯客")])])]):_vm._e(),_c('div',{staticClass:"px-3 pt-3 pb-2"},[_c('div',{staticClass:"search mb-2"},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 512 512"}},[_c('path',{attrs:{"d":"M500.3 443.7l-119.7-119.7c27.22-40.41 40.65-90.9 33.46-144.7C401.8 87.79 326.8 13.32 235.2 1.723C99.01-15.51-15.51 99.01 1.724 235.2c11.6 91.64 86.08 166.7 177.6 178.9c53.8 7.189 104.3-6.236 144.7-33.46l119.7 119.7c15.62 15.62 40.95 15.62 56.57 0C515.9 484.7 515.9 459.3 500.3 443.7zM79.1 208c0-70.58 57.42-128 128-128s128 57.42 128 128c0 70.58-57.42 128-128 128S79.1 278.6 79.1 208z"}})]),_c('b-form-input',{attrs:{"type":"search","placeholder":"姓名搜尋"},model:{value:(_vm.keyword),callback:function ($$v) {_vm.keyword=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"keyword"}})],1),(false)?_c('div',{staticClass:"filter"},[_c('button',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-filter",modifiers:{"modal-filter":true}}],staticClass:"btn btn-xs font-weight-bold"},[_vm._v(" 新增篩選 "),_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 448 512"}},[_c('path',{attrs:{"d":"M432 256c0 17.69-14.33 32.01-32 32.01H256v144c0 17.69-14.33 31.99-32 31.99s-32-14.3-32-31.99v-144H48c-17.67 0-32-14.32-32-32.01s14.33-31.99 32-31.99H192v-144c0-17.69 14.33-32.01 32-32.01s32 14.32 32 32.01v144h144C417.7 224 432 238.3 432 256z"}})])]),_c('b-modal',{attrs:{"id":"modal-filter","size":"sm","header-class":"border-bottom-0","centered":""},scopedSlots:_vm._u([{key:"modal-header",fn:function(ref){
          var close = ref.close;
return [_c('h5',{staticClass:"m-0 align-self-center"},[_c('svg',{staticStyle:{"height":"20px"},attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 448 512"}},[_c('path',{attrs:{"d":"M48 32H197.5C214.5 32 230.7 38.74 242.7 50.75L418.7 226.7C443.7 251.7 443.7 292.3 418.7 317.3L285.3 450.7C260.3 475.7 219.7 475.7 194.7 450.7L18.75 274.7C6.743 262.7 0 246.5 0 229.5V80C0 53.49 21.49 32 48 32L48 32zM112 176C129.7 176 144 161.7 144 144C144 126.3 129.7 112 112 112C94.33 112 80 126.3 80 144C80 161.7 94.33 176 112 176z"}})]),_vm._v(" 新增篩選 ")]),_c('b-button',{staticClass:"text-dark",attrs:{"size":"sm","variant":"link"},on:{"click":function($event){return close()}}},[_c('svg',{staticStyle:{"height":"20px"},attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 320 512"}},[_c('path',{attrs:{"d":"M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"}})])])]}},{key:"modal-footer",fn:function(ref){
          var ok = ref.ok;
          var cancel = ref.cancel;
return [_c('div',{staticClass:"d-flex justify-content-between w-100"},[_c('b-button',{attrs:{"size":"lg","variant":"outline-dark mr-3 w-100"},on:{"click":cancel}},[_vm._v("取消")]),_c('b-button',{attrs:{"size":"lg","variant":"primary w-100"},on:{"click":ok}},[_vm._v("確認")])],1)]}}],null,false,761086935)},[_c('b-row',_vm._l((_vm.tagOptions),function(t,idx){return _c('b-col',{key:idx,staticClass:"my-2",attrs:{"cols":"6"}},[_c('b-button',{staticClass:"\n                py-2\n                px-3\n                text-left\n                font-weight-bold\n                d-flex\n                justify-content-between\n              ",attrs:{"block":"","size":"md","pill":"","variant":_vm.tags.includes(t) ? 'dark' : 'secondary'},on:{"click":function () {
                  var el = _vm.tags;
                  el.includes(t) ? el.splice(el.indexOf(t), 1) : el.push(t);
                }}},[_c('span',[_vm._v(_vm._s(t))]),(_vm.tags.includes(t))?_c('svg',{staticStyle:{"height":"15px","fill":"#fff"},attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 448 512"}},[_c('path',{attrs:{"d":"M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"}})]):_vm._e()])],1)}),1)],1)],1):_vm._e()]),_c('b-list-group',[(_vm.customers)?_vm._l((_vm.customers.filter(function (x) {
          return (
            _vm.keyword == '' ||
            x.name.toLowerCase().indexOf(_vm.keyword.toLowerCase()) >= 0 ||
            x.company_tax_number.indexOf(_vm.keyword) >= 0
          );
        })),function(i){return _c('b-list-group-item',{key:i.name,staticClass:"d-flex align-items-center",attrs:{"to":{
          name: 'LiffSalesCustomer_Chailease',
          params: {
            id: i.id,
          },
        }}},[_c('b-avatar',{staticClass:"mr-3",attrs:{"src":i.avatar_url}}),_c('div',{staticClass:"mr-auto font-weight-bold"},[_vm._v(" "+_vm._s(_vm.displayCustomerName(i))+" "),_vm._l((i.tags),function(t){return _c('b-badge',{key:t,staticClass:"text-dark",attrs:{"pill":"","variant":"light"}},[_vm._v(" "+_vm._s(t)+" ")])}),_c('div',{staticClass:"text-muted small"},[_vm._v(" "+_vm._s(_vm.format(new Date(i.bound_at), "yyyy/MM/dd HH:mm"))+" 建立 ")])],2),_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 256 512"}},[_c('path',{attrs:{"d":"M64 448c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L178.8 256L41.38 118.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25l-160 160C80.38 444.9 72.19 448 64 448z"}})])],1)}):_c('b-list-group-item',{staticClass:"empty"},[_c('img',{attrs:{"src":require("./../images/empty.svg")}}),_c('div',{staticClass:"mt-3 text-dark small"},[_vm._v("目前沒有名單")])])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }